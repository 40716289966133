import React, { Component } from "react";
import axios from "axios";
import "./WriteAReview.css";
import { Star } from "@styled-icons/bootstrap/";
import { StarFill } from "@styled-icons/bootstrap/";
import { At } from "@styled-icons/boxicons-regular/";
import { motion } from "framer-motion";
import { CheckmarkOutline } from "@styled-icons/evaicons-outline/";
import PopupSignup from "./PopupSignup";
import PopupLogin from "./PopupLogin";
class WriteAReview extends Component {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.state = {
      LoggedIn: null,
      receivedighandle: this.props.history.location.state.ighandle,
      followers: this.props.history.location.state.followerc,
      anonName: false,
      anonAff: false,
      choosingamount: 0,
      firstchoicelockedin: false,
      name: "",
      affiliation: "",
      subject: "",
      body: "",
      toSubmitReview: {},
      qualities: [],
      choicearray: [
        { name: "Timely Delivery", active: false, good: true },
        { name: "S l o w", active: false, good: false },
        { name: "Responsive", active: false, good: true },
        { name: "Not Responsive", active: false, good: false },
        { name: "Quality Content", active: false, good: true },
        { name: "Cuts Corners", active: false, good: false },
        { name: "Little Flexibility", active: false },
        { name: "Super Flexible", active: false, good: true },
        { name: "Expensive", active: false, good: false },
        { name: "Scammed!", active: false, good: false },
        { name: "I saw great results", active: false, good: true },
      ],
      choiceCount: 0,
      AnonMessage: null,
      Checkmark: false,
      showPopupLogin: false,
      showPopupSignup: false,
    };
  }
  componentDidMount = async () => {
    this.checkifLoggedin();
  };
  componentDidUpdate = async () => {};

  checkifLoggedin = async () => {
    var tokentrue = false;
    if (localStorage.getItem("token")) {
      await axios
        .post(
          "https://influencer-review.herokuapp.com/user/tokenIsValid",
          null,
          { headers: { "x-auth-token": localStorage.getItem("token") } }
        )
        .then(function (res) {
          tokentrue = res.data;
        });
      if (tokentrue) {
        this.setState({
          LoggedIn: "Yes",
        });
      }
    } else {
      this.setState({
        LoggedIn: "No",
      });
    }
  };

  routeChange() {
    let path = "/submitting";
    this.props.history.push({
      pathname: path,
      state: { ighandle: this.state.receivedighandle },
    });
  }
  togglePopupLogin() {
    const prev = this.state.showPopupLogin;
    this.setState({
      showPopupLogin: !prev,
    });
    this.checkifLoggedin();
  }
  togglePopupSignup() {
    const prev = this.state.showPopupSignup;
    this.setState({
      showPopupSignup: !prev,
    });
    this.checkifLoggedin();
  }
  togglePopupLoginSignup() {
    const prev = this.state.showPopupSignup;
    this.setState({
      showPopupLogin: prev,
      showPopupSignup: !prev,
    });
    this.checkifLoggedin();
  }
  handleChange = (e) => {
    e.preventDefault();
    const [section, key] = e.target.name.split(".");
    if (key) {
      this.setState({
        ...this.state,
        [section]: {
          ...this.state[section],
          [key]: e.target.value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [section]: e.target.value,
      });
    }
  };
  handleEnterStar1 = () => {
    if (!this.state.firstchoicelockedin) {
      this.setState({
        choosingamount: 1,
      });
    }
  };
  handleEnterStar2 = () => {
    if (!this.state.firstchoicelockedin) {
      this.setState({
        choosingamount: 2,
      });
    }
  };
  handleEnterStar3 = () => {
    if (!this.state.firstchoicelockedin) {
      this.setState({
        choosingamount: 3,
      });
    }
  };
  handleEnterStar4 = () => {
    if (!this.state.firstchoicelockedin) {
      this.setState({
        choosingamount: 4,
      });
    }
  };
  handleEnterStar5 = () => {
    if (!this.state.firstchoicelockedin) {
      this.setState({
        choosingamount: 5,
      });
    }
  };

  handleLeaveStar = () => {
    if (!this.state.firstchoicelockedin) {
      this.setState({
        choosingamount: 0,
      });
    }
  };
  onClickStar1 = () => {
    this.setState({
      firstchoicelockedin: true,
      choosingamount: 1,
    });
  };
  onClickStar2 = () => {
    this.setState({
      firstchoicelockedin: true,
      choosingamount: 2,
    });
  };
  onClickStar3 = () => {
    this.setState({
      firstchoicelockedin: true,
      choosingamount: 3,
    });
  };
  onClickStar4 = () => {
    this.setState({
      firstchoicelockedin: true,
      choosingamount: 4,
    });
  };
  onClickStar5 = () => {
    this.setState({
      firstchoicelockedin: true,
      choosingamount: 5,
    });
  };
  toggleClass = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleAnonName = () => {
    const prev = this.state.anonName;
    this.setState({
      anonName: !prev,
    });
    if (!prev) {
      this.setState({
        name: "Anon",
      });
    }
  };
  handleAnonAff = () => {
    const prev = this.state.anonAff;
    this.setState({
      anonAff: !prev,
    });
    if (!prev) {
      this.setState({
        affiliation: "Anon",
      });
    }
  };
  submit = async (event) => {
    console.log(this.state.LoggedIn);
    if (
      this.state.name &&
      this.state.affiliation &&
      this.state.subject &&
      this.state.body &&
      this.state.choosingamount !== 0
    ) {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      var now = new Date();
      var thisMonth = months[now.getMonth()];
      var thisDay = now.getDate();
      var thisYear = now.getFullYear();
      var actualDate = thisDay + " " + thisMonth + " " + thisYear;
      var qualitiesArrayGood = [];
      var qualitiesArrayBad = [];

      for (var i = 0; i < this.state.choicearray.length; i++) {
        if (
          this.state.choicearray[i].active &&
          this.state.choicearray[i].good
        ) {
          qualitiesArrayGood.push(this.state.choicearray[i].name);
        }
        if (this.state.choicearray[i].active && !this.state.choicearray[i].good)
          qualitiesArrayBad.push(this.state.choicearray[i].name);
      }
      const submission = {
        name: this.state.name,
        affiliation: this.state.affiliation,
        subject: this.state.subject,
        body: this.state.body,
        rating: this.state.choosingamount,
        date: actualDate,
        Gqualities: qualitiesArrayGood,
        Bqualities: qualitiesArrayBad,
      };
      var influencer_id = "";
      var review_id = "";
      if (this.state.LoggedIn === "Yes") {
        console.log("wtf");
        await axios
          .post("https://influencer-review.herokuapp.com/testnewinf", {
            ighandle: this.state.receivedighandle,
            review: submission,
          })
          .then((res) => {
            influencer_id = res.data._id;
            review_id = res.data.reviews[0]._id;
          })
          .catch((error) => {
            console.log("got errr while posting data", error);
          });
        await axios
          .post("https://influencer-review.herokuapp.com/user/addedReview", {
            user_id: localStorage.getItem("user_id"),
            bundle: { ighandle: influencer_id, review: review_id },
          })
          .then((response) => {
            console.log("added on user side");
            this.routeChange();
          })
          .catch((error) => {
            console.log("got err while posting on user side", error);
          });
      } else {
        await axios
          .post("https://influencer-review.herokuapp.com/testnewinf", {
            ighandle: this.state.receivedighandle,
            review: submission,
          })
          .then((res) => {
            influencer_id = res.data._id;
            review_id = res.data.reviews[0]._id;
            this.routeChange();
          })
          .catch((error) => {
            console.log("got errr while posting data", error);
          });
      }
    }
  };

  render() {
    if (!this.state.LoggedIn) {
      return <div />;
    }
    return (
      <motion.div
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      >
        <div className="topleft-div">
          <button className="profile-image"></button>
          <a className="handle">{this.state.receivedighandle}</a>
          <a className="follower-count">{this.state.followers}</a>
          <At className="at"></At>
        </div>
        <a className="writeyourreview">Write your review:</a>
        <div className="stars-div" style={{ display: "flex" }}>
          {this.state.choosingamount >= 1 ? (
            <StarFill
              onClick={this.onClickStar1}
              onMouseLeave={this.handleLeaveStar}
              className="filled-stars"
            ></StarFill>
          ) : (
            <Star
              onClick={this.onClickStar1}
              onMouseEnter={this.handleEnterStar1}
              className="empty-stars"
            ></Star>
          )}
          {this.state.choosingamount >= 2 ? (
            <StarFill
              onClick={this.onClickStar2}
              onMouseLeave={this.handleLeaveStar}
              className="filled-stars"
            ></StarFill>
          ) : (
            <Star
              onClick={this.onClickStar2}
              onMouseEnter={this.handleEnterStar2}
              className="empty-stars"
            ></Star>
          )}
          {this.state.choosingamount >= 3 ? (
            <StarFill
              onClick={this.onClickStar3}
              onMouseLeave={this.handleLeaveStar}
              className="filled-stars"
            ></StarFill>
          ) : (
            <Star
              onClick={this.onClickStar3}
              onMouseEnter={this.handleEnterStar3}
              className="empty-stars"
            ></Star>
          )}
          {this.state.choosingamount >= 4 ? (
            <StarFill
              onClick={this.onClickStar4}
              onMouseLeave={this.handleLeaveStar}
              className="filled-stars"
            ></StarFill>
          ) : (
            <Star
              onClick={this.onClickStar4}
              onMouseEnter={this.handleEnterStar4}
              className="empty-stars"
            ></Star>
          )}
          {this.state.choosingamount >= 5 ? (
            <StarFill
              onClick={this.onClickStar5}
              onMouseLeave={this.handleLeaveStar}
              className="filled-stars"
            ></StarFill>
          ) : (
            <Star
              onClick={this.onClickStar5}
              onMouseEnter={this.handleEnterStar5}
              className="empty-stars"
            ></Star>
          )}
        </div>

        <a className="headings" style={{ top: "65px", left: "551px" }}>
          Name
        </a>
        <a className="headings" style={{ top: "138px", left: "551px" }}>
          Place of Work/Affiliation
        </a>

        <a className="headings" style={{ top: "275px", left: "551px" }}>
          Subject
        </a>
        <a className="headings" style={{ top: "347px", left: "551px" }}>
          Feedback
        </a>
        <a className="or" style={{ top: "95px", left: "798px" }}>
          or
        </a>
        <a className="or" style={{ top: "168px", left: "798px" }}>
          or
        </a>

        <input
          name="name"
          autoComplete="off"
          spellCheck="false"
          className="oneliner-input"
          style={{ top: "90px", left: "541px", width: "239px" }}
          placeholder={
            this.state.anonName ? "They'll never find you" : "Enter your name"
          }
          onChange={this.handleChange}
          readOnly={this.state.anonName ? true : false}
        ></input>
        <input
          name="affiliation"
          autoComplete="off"
          onChange={this.handleChange}
          spellCheck="false"
          className="oneliner-input"
          style={{ top: "163px", left: "541px", width: "239px" }}
          placeholder={
            this.state.anonAff
              ? "They'll never find you"
              : "Enter your place of work"
          }
          readOnly={this.state.anonAff ? true : false}
        ></input>

        <input
          name="subject"
          autoComplete="off"
          onChange={this.handleChange}
          spellCheck="false"
          className="oneliner-input"
          style={{ top: "300px", left: "541px", width: "527px" }}
          placeholder="Enter subject header"
        ></input>
        <textarea
          name="body"
          autoComplete="off"
          onChange={this.handleChange}
          className={
            this.state.body === "" ? "body-input" : "body-input-istyping"
          }
          placeholder="Write your review"
          spellCheck="false"
          maxLength="800"
        ></textarea>
        <button
          onClick={this.handleAnonName}
          className={this.state.anonName ? "stayanon-Clicked" : "stayanon"}
          style={{ top: "90px", left: "829px" }}
        >
          Stay Anonymous
        </button>
        <button
          onClick={this.handleAnonAff}
          className={this.state.anonAff ? "stayanon-Clicked" : "stayanon"}
          style={{ top: "163px", left: "829px" }}
        >
          Stay Anonymous
        </button>
        <button
          onClick={(event) => {
            if (
              (this.state.LoggedIn === "Yes" &&
                this.state.name &&
                this.state.affiliation &&
                this.state.subject &&
                this.state.body &&
                this.state.choosingamount !== 0) ||
              (this.state.LoggedIn === "No" &&
                this.state.name &&
                this.state.affiliation &&
                this.state.subject &&
                this.state.body &&
                this.state.Checkmark &&
                this.state.choosingamount !== 0)
            ) {
              this.submit();
            }
          }}
          className={
            ((this.state.LoggedIn === "Yes" &&
            this.state.name &&
            this.state.affiliation &&
            this.state.subject &&
            this.state.body &&
            this.state.choosingamount !== 0) ||
          (this.state.LoggedIn === "No" &&
            this.state.name &&
            this.state.affiliation &&
            this.state.subject &&
            this.state.body &&
            this.state.Checkmark &&
            this.state.choosingamount !== 0)
        )
              ? "submit-purple"
              : "submit"
          }
        >
          Submit
        </button>
        {this.state.LoggedIn === "No" && (
          <div>
            <button
              className="checkmark-button"
              onClick={() => {
                const prevCheckmark = this.state.Checkmark;
                this.setState({
                  Checkmark: !prevCheckmark,
                });
              }}
            >
              <CheckmarkOutline
                className={
                  this.state.Checkmark ? "checkmark-yes" : "checkmark-no"
                }
              ></CheckmarkOutline>
            </button>

            <a className="checkmark-message">
              As an anonymous user, you won't be able to edit or delete your
              review. Or,
            </a>
            <a
              className="loginInstead"
              onClick={() => {
                this.togglePopupLogin();
              }}
            >
              Log in/Signup
            </a>
          </div>
        )}

        <a className="wordcount">
          {this.state.body.length + "/800 characters"}
        </a>
        <a className="choose3">Choose up to three:</a>
        <div className="choicebuttons">
          {this.state.choicearray.map((choice, index) => {
            return (
              <button
                onClick={() => {
                  if (this.state.choiceCount < 3) {
                    const prev = this.state.choicearray[index].active;
                    const newIds = this.state.choicearray.slice(); //copy the array
                    newIds[index].active = !prev; //execute the manipulations
                    var prevchoiceCount = this.state.choiceCount;
                    if (!prev) {
                      this.setState({
                        choicearray: newIds,
                        choiceCount: prevchoiceCount + 1,
                      });
                    } else {
                      this.setState({
                        choicearray: newIds,
                        choiceCount: prevchoiceCount - 1,
                      });
                    }
                  } else {
                    if (this.state.choicearray[index].active) {
                      const newIds = this.state.choicearray.slice();
                      newIds[index].active = false;
                      prevchoiceCount = this.state.choiceCount;
                      this.setState({
                        choicearray: newIds,
                        choiceCount: prevchoiceCount - 1,
                      });
                    }
                  }
                }}
                className={
                  choice.active
                    ? "choicebuttonsactual-clicked"
                    : "choicebuttonsactual"
                }
              >
                {choice.name}
              </button>
            );
          })}
        </div>
        {this.state.showPopupSignup ? (
          <PopupSignup
            closePopup={this.togglePopupSignup.bind(this)}
            change={this.togglePopupLoginSignup.bind(this)}
            intent={"writing"}
          ></PopupSignup>
        ) : null}
        {this.state.showPopupLogin ? (
          <PopupLogin
            closePopup={this.togglePopupLogin.bind(this)}
            change={this.togglePopupLoginSignup.bind(this)}
            intent={"writing"}
          ></PopupLogin>
        ) : null}
      </motion.div>
    );
  }
}

export default WriteAReview;
