import React, { Component } from 'react';
import Sidebar from './Sidebar'
import './Aboutus.css'
import {ThreeBars} from '@styled-icons/octicons/'
class Aboutus extends Component {
    constructor(props){
        super(props)
        this.state={
            showSidebar:false,
        }
    }
    toggleSidebar() {
        const prev = this.state.showSidebar;
        this.setState({
          showSidebar: !prev,
        });
      }
    render() {
        return (
            <div>
                <ThreeBars onClick={()=>{this.toggleSidebar()}} className="threebars"></ThreeBars>
                <a target="_blank" className="auHeader">About us</a>
                <a target="_blank" className="auParagraph">
                  Alien Tempo collects crowd-sourced reviews from marketers about their experience with influencers. We hope these reviews can deter
                  influencer scams and promote better chemistry among the marketer and the influencer.
                </a>
                {this.state.showSidebar ? (
          <Sidebar
            closePopup={this.toggleSidebar.bind(this)}
            change={this.toggleSidebar.bind(this)}
            highlighted={'a'}
          ></Sidebar>
        ) : null}
            </div>
        );
    }
}

export default Aboutus;