import React, { Component } from 'react';
import axios from 'axios';
import "./Influencer.css";
import { StarFill } from "@styled-icons/bootstrap/";
import { Suitcase } from "@styled-icons/entypo/Suitcase";
import { List } from "semantic-ui-react";
import { Star } from "@styled-icons/bootstrap/";
import ShowMoreText from 'react-show-more-text'
import Sidebar from './Sidebar'
import {ThreeBars} from '@styled-icons/octicons/'
import {Trash} from "@styled-icons/boxicons-regular/"
import './MyReviews.css'
import DeletePopup from './DeletePopup'
import { LoaderAlt } from "@styled-icons/boxicons-regular/";

class MyReviews extends Component {
    constructor(props){
        super(props);
        this.state={
            myReviews:null,
            showSidebar:false,
            showDeletePopup:false,
            trashHover: false,
            potenInfId:'',
            potenRevId:'',
            potenUserId:'',
            message: null,
            loading: false,
        }
        
        
    }
    toggleSidebar() {
      const prev = this.state.showSidebar;
      this.setState({
        showSidebar: !prev,
      });
    }
    toggleDeletePopup() {
      const prev = this.state.showDeletePopup;
      this.setState({
        showDeletePopup: !prev,
      });
    }
    delete=()=>{
      axios.post("https://influencer-review.herokuapp.com/user/deleteReview",{
          inf_id:this.state.potenInfId,
          rev_id:this.state.potenRevId,
          user_id:this.state.potenUserId,
      }).then((res)=>{
        
        
        this.setState({
          showDeletePopup: false,
          myReviews: null,
        });
      })
  }
    componentDidMount = () =>{
        axios.post('https://influencer-review.herokuapp.com/user/getMyReviews',{
            user_id: localStorage.getItem("user_id")
        }).then((res)=>{
          console.log(res)
            if(res.data.length === 0){
              this.setState({
                message:"You didn't write any reviews... yet.",
            })
            } else {
            this.setState({
                myReviews: res.data
            })
          }
        
        })
    }
    componentDidUpdate = () =>{
      axios.post('https://influencer-review.herokuapp.com/user/getMyReviews',{
          user_id: localStorage.getItem("user_id")
      }).then((res)=>{
          if(res.data.length === 0){
            this.setState({
              message:"You didn't write any reviews... yet.",
          })
          } else {
          this.setState({
              myReviews: res.data
          })
        }
      
      })
  }
    makeAllQ(review) {
        var allQualities = [];
    
        for (var j = 0; j < review.Gqualities.length; j++) {
          allQualities.push(review.Gqualities[j]);
        }
        for (var k = 0; k < review.Bqualities.length; k++) {
          allQualities.push(review.Bqualities[k]);
        }
    
        return allQualities;
      }
    render() {
        return (
          <div>
          <ThreeBars onClick={()=>{this.toggleSidebar()}} className="threebars"></ThreeBars>
        <div className="entirelist">
          {this.state.myReviews !== null ? (
            <List style={{ position: "relative" }}>
              {this.state.myReviews.map((r) => {
                return (
                  <List.Item className="reviews-div">
                    <List className="list">
                        <List.Item>
                            <a className="reviews-name">For @{r.influencer}:</a>
                            <Trash onClick={()=>{this.setState({
                              potenInfId:r.influencer_id,
                              potenRevId:r.reviewObj._id,
                              potenUserId:localStorage.getItem('user_id'),
                            })
                            this.toggleDeletePopup()}} className="trashiconNH"></Trash>
                        </List.Item>
                      <List.Item style={{ padding: "0px 0px 2px 0px" }}>
                        <a className="reviews-name">{r.reviewObj.name}</a>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 5px 0px" }}>
                        <div style={{ display: "flex" }}>
                          <Suitcase className="reviews-suitcase"></Suitcase>
                          <a className="reviews-company">
                            {r.reviewObj.affiliation}
                          </a>
                        </div>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 5px 0px" }}>
                        <div style={{ display: "flex" }}>
                          {r.reviewObj.rating >= 1 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                          {r.reviewObj.rating >= 2 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                          {r.reviewObj.rating >= 3 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                          {r.reviewObj.rating >= 4 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                          {r.reviewObj.rating >= 5 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                        </div>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 3px 0px" }}>
                        <a className="reviews-subject">{r.reviewObj.subject}</a>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 5px 0px" }}>
                      <ShowMoreText 
                        lines={3}
                        more='Show more'
                        less='Show less'
                        className='reviews-paragraph'
                        expanded={false}
                        width={280}>
                          {r.reviewObj.body}
                        </ShowMoreText>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 5px 0px" }}>
                        <div className="divfortop">
                          {this.makeAllQ(r.reviewObj).map((value) => {
                            return (
                              <div>
                                <button className="quality2">{value}</button>
                              </div>
                            );
                          })}
                        </div>
                      </List.Item>

                      <List.Item>
                        <a className="reviews-date">{r.reviewObj.date}</a>
                      </List.Item>
                    </List>
                  </List.Item>
                );
              })}
            </List>
          ) : (
            <div>
              {this.state.message ? <a className="noreviews">{this.state.message}</a> : <LoaderAlt className="loader" style={{top:"10px",left:'10px'}}></LoaderAlt>}
              
              
            </div>
          )}
          
        </div>
        {this.state.showSidebar ? (
          <Sidebar
            closePopup={this.toggleSidebar.bind(this)}
            change={this.toggleSidebar.bind(this)}
            highlighted={"m"}
          ></Sidebar>
        ) : null}
        {this.state.showDeletePopup ? (
          <DeletePopup
            closePopup={this.toggleDeletePopup.bind(this)}
            poten={{potenInfId: this.state.potenInfId, potenRevId: this.state.potenRevId, potenUserId: this.state.potenUserId}}
            delete={this.delete}
          ></DeletePopup>
        ) : null}
        </div>
        );
    }
}

export default MyReviews;