import React, { Component } from "react";
import "./Sidebar.css";
import { ThreeBars } from "@styled-icons/octicons/";
import { HomeAlt } from "@styled-icons/boxicons-regular/";
import { DocumentText } from "@styled-icons/ionicons-outline/";
import { QuestionCircle } from "@styled-icons/bootstrap/QuestionCircle";
import { PhoneOutline } from "@styled-icons/evaicons-outline/";
import { withRouter } from "react-router-dom";
import PopupLogin from "./PopupLogin";
import PopupSignup from "./PopupSignup";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.state = {
      mrclicked: this.props.highlighted === "m",
      auclicked: this.props.highlighted === "a",
      cuclicked: this.props.highlighted === "c",
      name: null,
      loggedin: localStorage.getItem("userjson") !== null,
      showPopupSignup: false,
      showPopupLogin: false,
      justsignedup: false,
    };
  }
  togglePopupSignup() {
    const prev = this.state.showPopupSignup;
    const userdata = localStorage.getItem("userjson");
    this.setState({
      showPopupSignup: !prev,
      loggedin: userdata!==null,
      name: (userdata!==null?JSON.parse(userdata).firstname + " " + JSON.parse(userdata).lastname:""),
    });
  }

  togglePopupLogin=()=> {
    const prev = this.state.showPopupLogin;
    const userdata = localStorage.getItem("userjson");
    this.setState({
      showPopupLogin: !prev,
      loggedin: userdata!==null,
      name: (userdata!==null?JSON.parse(userdata).firstname + " " + JSON.parse(userdata).lastname:""),
    });
  }

  componentDidMount = () => {
    if (this.state.loggedin) {
      const userdata = JSON.parse(localStorage.getItem("userjson"));
      this.setState({
        loggedin: true,
        name: userdata.firstname + " " + userdata.lastname,
      });
    }
  };
  routeChange(screen) {
    let path = `/` + screen;
    this.props.history.push({
      pathname: path,
    });
  }
  togglePopupLoginSignup() {
    const prev = this.state.showPopupSignup;
    this.setState({
      showPopupLogin: prev,
      showPopupSignup: !prev,
      
    });
  }
  render() {
    return (
      <div className="SBpopup">
        <div className="SBpopup_inner">
          <ThreeBars
            onClick={this.props.closePopup}
            className="closeThree"
          ></ThreeBars>
          {this.props.highlighted ? (
            <HomeAlt
              className="home"
              onClick={() => {
                this.routeChange("");
              }}
            ></HomeAlt>
          ) : null}
          {this.state.loggedin ? (
            <div>
              <button className="profileimage"></button>
              <a className="name-sidebar">{this.state.name}</a>
              <button
                onClick={() => {
                  if(this.state.mrclicked){
                    this.props.closePopup();
                  }else{
                    this.routeChange("my-reviews");
                  }
                  
                }}
                className={
                  this.state.mrclicked ? "myreviews-tab" : "myreviews-tabNC"
                }
                style={{ top: "311px" }}
              >
                <DocumentText className="myreviews-icon"></DocumentText>
                My Reviews
              </button>
            </div>
          ) : (
            <div>
              <button className="loginbutton" onClick={()=>{this.togglePopupLogin()}}>Log in</button>
            </div>
          )}
          <button
            onClick={() => {
              if(this.state.auclicked){
                this.props.closePopup();
              }else{
              this.routeChange("about");
              }
            }}
            className={
              this.state.auclicked ? "myreviews-tab" : "myreviews-tabNC"
            }
            style={{ top: this.state.loggedin?"361px":"100px" }}
          >
            <QuestionCircle className="aboutus-icon"></QuestionCircle>
            About us
          </button>
          <button
            onClick={() => {
              if(this.state.cuclicked){
                this.props.closePopup();
              }else{
                this.routeChange("contact");
              }
              
            }}
            className={
              this.state.cuclicked ? "myreviews-tab" : "myreviews-tabNC"
            }
            style={{ top: this.state.loggedin?"411px":"150px" }}
          >
            <PhoneOutline className="contact-icon"></PhoneOutline>
            Contact us
          </button>
          {this.state.loggedin ? (
            <div>
              <button onClick={()=>{
                  localStorage.clear();
                  this.setState({
                  loggedin:false,
              })}} className="signoutbutton">Sign Out</button>
            </div>
          ) : null}
          {this.state.showPopupSignup ? (
          <PopupSignup
            closePopup={this.togglePopupSignup.bind(this)}
            change={this.togglePopupLoginSignup.bind(this)}
          ></PopupSignup>
        ) : null}
        {this.state.showPopupLogin ? (
          <PopupLogin
            closePopup={this.togglePopupLogin.bind(this)}
            change={this.togglePopupLoginSignup.bind(this)}
            updateParent={()=>{this.forceUpdate()}}
          ></PopupLogin>
        ) : null}
        </div>
      </div>
    );
  }
}

export default withRouter(Sidebar);
