import React, { Component } from 'react';
import './PopupLogin.css'
import {CloseOutline} from '@styled-icons/evaicons-outline'
import {GoogleLogin} from 'react-google-login'
import axios from 'axios'
import { LoaderAlt } from "@styled-icons/boxicons-regular/";
class PopupLogin extends Component {
  constructor(props){
    super(props)
    this.standardLogin = this.standardLogin.bind(this);
    this.state={
        email: '',
        password: '',
        loginerror:'',
        loading: false,
    }
}
standardLogin =async()=>{
  this.setState({
    loading:true,
  })
  await axios.post("https://influencer-review.herokuapp.com/user/login",{
      email: this.state.email,
      password: this.state.password
  }).then((res)=>{
      if(!res.data.token){
        this.setState({
          loginerror: res.data.msg,
          loading: false,
        })
      }else{
        this.setState({
          loading:false,
        })
      localStorage.setItem('token', res.data.token)
      localStorage.setItem('user_id',res.data.user.id)
      localStorage.setItem('userjson',JSON.stringify(res.data.user));
      }
  })
}
responseGoogle = async(response) => {
  try{
    await axios.post("https://influencer-review.herokuapp.com/user/login",{
      email: response.profileObj.email,
      password: "GoogleLoginSlick",
      }).then(function(res){
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('user_id',res.data.user.id);
        localStorage.setItem('userjson',JSON.stringify(res.data.user));
      })
    }catch(err){
      await axios.post("https://influencer-review.herokuapp.com/user/signup",{
        email: response.profileObj.email,
        firstname: response.profileObj.givenName,
        password: "GoogleLoginSlick",
        lastname: response.profileObj.familyName,
        }).then(function (res){
            console.log("submitted user")
        });
        await axios.post("https://influencer-review.herokuapp.com/user/login",{
          email: response.profileObj.email,
          password: "GoogleLoginSlick",
          }).then(function (res){
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('user_id',res.data.user.id);
            localStorage.setItem('userjson',JSON.stringify(res.data.user));
          });
    }
  this.props.closePopup();
  
}
handleChange = (e) => {
  this.setState({
    [e.target.name]: e.target.value,
  });
};
    render() {
      return (
        <div className='popup'>
          <div className='popup_inner'>
            {this.state.loading?<LoaderAlt style={{top:'337px', left:'320px'}}className="loader"></LoaderAlt>:null}
            <a className='loginheader'>Log in</a>
            <a className='donthaveaccount' >Don't have an account?</a>
            <a className='donthaveaccount2' onClick={this.props.change}>Sign up</a>
            <a className='headers' style={{top:'151px',left:'45px'}}>Email</a>
            <a className='headers' style={{top:'222px',left:'45px'}}>Password</a>
            <input onChange={this.handleChange} name="email" placeholder="Enter your email address" className='inputs' style={{top:'176px'}}></input>
            <input onChange={this.handleChange} type="password" name="password" placeholder="Enter your password" className='inputs' style={{top:'247px'}}></input>
            <a className='error'>{this.state.loginerror}</a>
            {/* <GoogleLogin
            className="logingoogle"
            clientId="248753337271-s4pa70pi1h6pt9pvbuv8f3q6pqr8kut0.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            cookiePolicy={'single_host_origin'}>

            </GoogleLogin> */}
            <button className='login' onClick={async()=>{
              
              if(!this.state.loading){
              await this.standardLogin();
              }
              if(localStorage.getItem('token')){
                this.props.closePopup();
              }
            }}>
                Log in</button>
            <CloseOutline className='cross' onClick={this.props.closePopup} ></CloseOutline>
            
          </div>
        </div>
      );
    }
  }

export default PopupLogin;