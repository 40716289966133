import React, { Component } from 'react';
import './Popup.css'
class Popup extends Component {
    render() {
      return (
        <div className='popup'>
          <div className='popup_innerF'>
            <a className = 'textheader'>{this.props.text}</a>
          <button className = 'closeButton'onClick={this.props.closePopup}>Okay</button>
          </div>
        </div>
      );
    }
  }

export default Popup;