import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Influencer from "./Influencer";
import Home from "./Home";
import WriteAReview from "./WriteAReview";
import Submitting from './Submitting'
import React, { Component } from "react";
import {AnimatePresence} from 'framer-motion';
import MyReviews from './MyReviews';
import AboutUs from './Aboutus';
import ContactUs from './Contactus'
import PopupSignup from './PopupSignup'
class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <AnimatePresence>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/influencer/:handle" component={Influencer} />
            <Route exact path="/influencer/:handle/write-a-review" component={WriteAReview} />
            <Route exact path='/submitting' component={Submitting}/>
            <Route exact path='/my-reviews' component={MyReviews}/>
            <Route exact path='/about' component={AboutUs}/>
            <Route exact path='/contact' component={ContactUs}/>
            <Route exact path='/SignupPopup' component={PopupSignup}/>
          </Switch>
          </AnimatePresence>
        </div>
      </Router>
    );
  }
}

export default App;
