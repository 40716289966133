import React, { Component } from 'react';
import './DeletePopup.css'
import axios from 'axios'
class DeletePopup extends Component {
    delete=()=>{
        const poten = this.props.poten
        axios.post("https://influencer-review.herokuapp.com/user/deleteReview",{
            inf_id:poten.potenInfId,
            rev_id:poten.potenRevId,
            user_id:poten.potenUserId,
        }).then((res)=>{
            this.props.closePopup();
        })
    }
    render() {
      return (
        <div className='popup'>
          <div className='popup_innerDP'>
            <a className = 'areyousure'>Are you sure you want to delete your review</a>
            <a className="Permanentmessage">This action is permanent and cannot be undone.</a>
            <button onClick={this.props.closePopup} className="cancelbuttonDP">Cancel</button>
            <button onClick={this.props.delete}className="deletebuttonDP">Delete</button>
          </div>
        </div>
      );
    }
  }

export default DeletePopup;