import React, { Component } from "react";
import "./Submitting.css";
import { Check2 } from "@styled-icons/bootstrap/";
import { motion } from "framer-motion";
class Submitting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change: false,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        change: true,
      });
    }, 3000);
    setTimeout(() => {
      let path = "influencer/" + this.props.history.location.state.ighandle;
      this.props.history.push({
        pathname: path,
      });
    }, 5000);
  }
  render() {
    return (
      <motion.div
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      >
        {this.state.change ? (
        
            <button className="firstButton">
              <Check2 className="checkmark"></Check2>
            </button>
        
        ) : (
       
          <button className="secondButton">Submitting...</button>
        
        )}
      </motion.div>
    );
  }
}

export default Submitting;
