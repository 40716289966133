import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Influencer.css";
import Popup from "./Popup";
import PopupSignup from "./PopupSignup"
import PopupLogin from "./PopupLogin"
import { At } from "@styled-icons/boxicons-regular/";
import { StarFill } from "@styled-icons/bootstrap/";
import { Pencil } from "@styled-icons/ionicons-sharp/";
import { Suitcase } from "@styled-icons/entypo/Suitcase";
import { List } from "semantic-ui-react";
import { PeopleFill } from "@styled-icons/bootstrap/";
import { Star } from "@styled-icons/bootstrap/";
import axios from "axios";
import { LoaderAlt } from "@styled-icons/boxicons-regular/";
import { motion } from "framer-motion";
import { Heart } from "@styled-icons/boxicons-regular/";
import ShowMoreText from 'react-show-more-text';

class Influencer extends Component {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.state = {
      receivedReviews: [],
      igvalue: "",
      lessLowF: false,
      overHighF: false,
      wantsToWrite: false,
      CWmessage: "",
      showPopup: false,
      showPopupSignup: false,
      showPopupLogin: false,
    };
  }

  componentDidMount = () => {
    try {
      this.setState({
        igvalue: this.props.history.location.state.igvalue,
      });
      const followercount = this.props.history.location.state.igvalue.split(
        ","
      )[0];
      var fvalue = parseInt(followercount.substring(1));
      if (fvalue < 5000) {
        this.setState({
          lessLowF: true,
          overHighF: false,
          CWmessage: "This account has less than 5k followers: Contact Us!",
        });
      } else if (fvalue > 10000000) {
        this.setState({
          lessLowF: false,
          overHighF: true,
          CWmessage: "This account has more than 10M followers: Contact Us!",
        });
      }
    } catch (error) {
      console.log(error);
      axios
        .post("https://influencer-review.herokuapp.com/getEngagement", {
          IGHANDLE: this.props.match.params.handle,
        })
        .then((response) => {
          console.log("Reloaded with out enter");
          const ighandlefromres = response.data;
          //const ighandletrimmed = ighandlefromres.trim()
          if (ighandlefromres.charAt(0) === "[") {
            this.setState({
              igvalue: ighandlefromres,
            });
            const followercount = this.state.igvalue.split(",")[0];
            var fvalue = parseInt(followercount.substring(1));
            if (fvalue < 5000) {
              this.setState({
                lessLowF: true,
                overHighF: false,
                CWmessage:
                  "This account has less than 5k followers: Contact Us!",
              });
            } else if (fvalue > 10000000) {
              this.setState({
                lessLowF: false,
                overHighF: true,
                CWmessage:
                  "This account has more than 10M followers: Contact Us!",
              });
            }
          }
        })
        .catch((error) => {
          console.log("got errr while posting data", error);
        });
    }
    var self = this;
    //https://influencer-review.herokuapp.com/getReviews
    axios
      .post("https://influencer-review.herokuapp.com/getReviewsNew", {
        ighandle: this.props.match.params.handle,
      })
      .then(function (response) {
        self.setState({ receivedReviews: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  checkifLoggedin = async () => {
    var tokentrue = false;
    if (localStorage.getItem("token")) {
      await axios
        .post(
          "https://influencer-review.herokuapp.com/user/tokenIsValid",
          null,
          { headers: { "x-auth-token": localStorage.getItem("token") } }
        )
        .then(function (res) {
          tokentrue = res.data;
        });
      
      return tokentrue;
    } else {
      return false;
    }
  };

  routeChange=async(followers)=> {
    const loggedin = this.checkifLoggedin();
    let path =
      "/influencer/" + this.props.match.params.handle + `/write-a-review`;
    this.props.history.push({
      loggedin: loggedin,
      pathname: path,
      state: {
        ighandle: this.props.match.params.handle,
        followerc: followers,
      },
    });
  }
  
  
  SearchAgainRouteChange() {
    let path = "/";
    this.props.history.push({
      pathname: path,
    });
  }

  togglePopup() {
    const prev = this.state.showPopup;
    this.setState({
      showPopup: !prev,
    });
  }

  togglePopupSignup() {
    const prev = this.state.showPopupSignup;
    this.setState({
      showPopupSignup: !prev,
    });
  }

  togglePopupLogin() {
    const prev = this.state.showPopupLogin;
    this.setState({
      showPopupLogin: !prev,
    });
  }

  togglePopupLoginSignup() {
    const prev = this.state.showPopupSignup;
    this.setState({
      showPopupLogin: prev,
      showPopupSignup: !prev,
    });
  }
  

  mode(array) {
    if (array.length === 0) return null;
    var modeMap = {};
    var maxEl = array[0],
      maxCount = 1;
    for (var i = 0; i < array.length; i++) {
      var el = array[i];
      if (modeMap[el] == null) modeMap[el] = 1;
      else modeMap[el]++;
      if (modeMap[el] > maxCount) {
        maxEl = el;
        maxCount = modeMap[el];
      }
    }

    return maxEl;
  }
  makeAllQ(review) {
    var allQualities = [];

    for (var j = 0; j < review.Gqualities.length; j++) {
      allQualities.push(review.Gqualities[j]);
    }
    for (var k = 0; k < review.Bqualities.length; k++) {
      allQualities.push(review.Bqualities[k]);
    }

    return allQualities;
  }

  render() {
    var GQualitiesAll = [];
    var BQualitiesAll = [];
    var sumofrating = 0;
    var ratingFrequency = [0, 0, 0, 0, 0];
    var basedOnString = "based on 0 reviews";
    if (this.state.receivedReviews !== "No reviews") {
      basedOnString =
        "based on " + this.state.receivedReviews.length + " reviews";
      for (var i = 0; i < this.state.receivedReviews.length; i++) {
        sumofrating = sumofrating + this.state.receivedReviews[i].rating;
        ratingFrequency[this.state.receivedReviews[i].rating - 1]++;
        for (
          var j = 0;
          j < this.state.receivedReviews[i].Gqualities.length;
          j++
        ) {
          GQualitiesAll.push(this.state.receivedReviews[i].Gqualities[j]);
        }
        for (
          var k = 0;
          k < this.state.receivedReviews[i].Bqualities.length;
          k++
        ) {
          BQualitiesAll.push(this.state.receivedReviews[i].Bqualities[k]);
        }
      }
    }
    var averageRating = parseFloat(
      sumofrating / this.state.receivedReviews.length
    ).toFixed(2);

    var topthreeG = [];
    var topthreeB = [];
    while (topthreeG.length < 3 && GQualitiesAll.length > 0) {
      var element = this.mode(GQualitiesAll);
      topthreeG.push(element);
      var end = GQualitiesAll.length;
      for (var i1 = 0; i1 < end; i1++) {
        if (GQualitiesAll[i1] === element) {
          GQualitiesAll.splice(i1, 1);
        }
      }
    }
    while (topthreeB.length < 3 && BQualitiesAll.length > 0) {
      var element2 = this.mode(BQualitiesAll);
      topthreeB.push(element2);
      var end2 = BQualitiesAll.length;
      for (var i = 0; i < end2; i++) {
        if (BQualitiesAll[i] === element2) {
          BQualitiesAll.splice(i, 1);
        }
      }
    }
    topthreeB = [...new Set(topthreeB)]
    topthreeG = [...new Set(topthreeG)]
  


    var highestFrequency = 0;
    var highRating = -1;
    for (var freqC = 0; freqC < ratingFrequency.length; freqC++) {
      if (ratingFrequency[freqC] > highestFrequency) {
        highestFrequency = ratingFrequency[freqC];
        highRating = freqC;
      }
    }
    const engstring = this.state.igvalue.split(",")[1];
    const fcount = this.state.igvalue.split(",")[0];
    var fcountdisplay = fcount.substring(1);
    if (fcountdisplay.length > 6) {
      fcountdisplay = fcountdisplay.substring(0, fcountdisplay.length - 6);
      fcountdisplay = fcountdisplay + "m followers";
    } else if (fcountdisplay.length > 3) {
      fcountdisplay = fcountdisplay.substring(0, fcountdisplay.length - 3);
      fcountdisplay = fcountdisplay + "k followers";
    } else {
      fcountdisplay = fcountdisplay + " followers";
    }
    const engpercent = (parseFloat(engstring) * 100).toFixed(2);

    return (
      <motion.div
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      >
        <div className="background-rectangle">
          {this.props.match.params.handle === "vickyyoon" ? (
            <Heart className="vicky"></Heart>
          ) : null}
          <div className="inner-content">
            
          </div>
          <button className="profile-pic"></button>
          <a className="follower-count">{fcountdisplay}</a>
          {this.state.igvalue !== "" ? null : (
            <LoaderAlt
              className="loader-inf"
              style={{ top: "90px", left: "250px" }}
            ></LoaderAlt>
          )}

          <At className="at"></At>
          <a className="ighandle">{this.props.match.params.handle}</a>
          <StarFill className="star"></StarFill>
          <PeopleFill className="engagement-icon"></PeopleFill>

          {isNaN(engpercent) ? (
            <LoaderAlt
              className="loader-inf"
              style={{ top: "173px", left: "70px" }}
            ></LoaderAlt>
          ) : (
            <a className="engagement-percentage">{engpercent + "%"}</a>
          )}
          <a className="of-five">of 5</a>
          <a className="engagementrate">engagement rate</a>

          {isNaN(averageRating) ? (
            <LoaderAlt
              className="loader-inf"
              style={{ top: "243px", left: "70px" }}
            ></LoaderAlt>
          ) : (
            <a className="rating">{averageRating}</a>
          )}
          <a className="basedonreview">{basedOnString}</a>
          <a className="like">What sponsors like:</a>

          <div className="bar" style={{ top: "300px" }}></div>
          <div
            className={highRating === 4 ? "bar-purple" : "bar-lightpurple"}
            style={{
              top: "300px",
              width:
                (ratingFrequency[4] / this.state.receivedReviews.length) * 266 +
                "px",
            }}
          ></div>
          <div className="bar" style={{ top: "323px" }}></div>
          <div
            className={highRating === 3 ? "bar-purple" : "bar-lightpurple"}
            style={{
              top: "323px",
              width:
                (ratingFrequency[3] / this.state.receivedReviews.length) * 266 +
                "px",
            }}
          ></div>
          <div className="bar" style={{ top: "346px" }}></div>
          <div
            className={highRating === 2 ? "bar-purple" : "bar-lightpurple"}
            style={{
              top: "346px",
              width:
                (ratingFrequency[2] / this.state.receivedReviews.length) * 266 +
                "px",
            }}
          ></div>
          <div className="bar" style={{ top: "369px" }}></div>
          <div
            className={highRating === 1 ? "bar-purple" : "bar-lightpurple"}
            style={{
              top: "369px",
              width:
                (ratingFrequency[1] / this.state.receivedReviews.length) * 266 +
                "px",
            }}
          ></div>
          <div className="bar" style={{ top: "392px" }}></div>
          <div
            className={highRating === 0 ? "bar-purple" : "bar-lightpurple"}
            style={{
              top: "392px",
              width:
                (ratingFrequency[0] / this.state.receivedReviews.length) * 266 +
                "px",
            }}
          ></div>
          <a className="bar-numbering" style={{ top: "298px" }}>
            5
          </a>
          <a className="bar-numbering" style={{ top: "321px" }}>
            4
          </a>
          <a className="bar-numbering" style={{ top: "344px" }}>
            3
          </a>
          <a className="bar-numbering" style={{ top: "367px" }}>
            2
          </a>
          <a className="bar-numbering" style={{ top: "390px" }}>
            1
          </a>

          <a className="bar-amount" style={{ top: "298px" }}>
            {ratingFrequency[4]}
          </a>
          <a className="bar-amount" style={{ top: "321px" }}>
            {ratingFrequency[3]}
          </a>
          <a className="bar-amount" style={{ top: "344px" }}>
            {ratingFrequency[2]}
          </a>
          <a className="bar-amount" style={{ top: "367px" }}>
            {ratingFrequency[1]}
          </a>
          <a className="bar-amount" style={{ top: "390px" }}>
            {ratingFrequency[0]}
          </a>

          <div className="divforgood">
            {topthreeG.map((value) => {
              return (
                <div>
                  <button className="quality">{value}</button>
                </div>
              );
            })}
          </div>
          <div className="divforbad">
            {topthreeB.map((value) => {
              return (
                <div>
                  <button className="quality">{value}</button>
                </div>
              );
            })}
          </div>

          <a className="dislike">What needs improvement:</a>
          {this.state.igvalue !== "" ? (
            <button
              className="writebutton"
              onClick={async() => {
                if (
                  !this.state.lessLowF &&
                  !this.state.overHighF &&
                  this.state.igvalue !== ""
                ){
                this.routeChange(fcountdisplay);
                } else {
                  this.togglePopup();
                }
                /*
                var tokentrue = false;
                if (
                  !this.state.lessLowF &&
                  !this.state.overHighF &&
                  this.state.igvalue !== ""
                ) {
                  if (localStorage.getItem('Googletoken')) {
                    await axios.post("https://influencer-review.herokuapp.com/user/googleTokenIsValid",{
                      googletoken: localStorage.getItem('Googletoken')
                    }).then(function (res){
                      tokentrue = res.data
                    });
                    if(tokentrue){
                      this.routeChange(fcountdisplay);
                    }
                  } else if (localStorage.getItem('token')){
                    await axios
                      .post(
                        "https://influencer-review.herokuapp.com/user/tokenIsValid",
                        null,
                        { headers: { "x-auth-token": localStorage.getItem("token") } }
                      )
                      .then(function (res) {
                        tokentrue = res.data
                      });
                      if(tokentrue){
                        this.routeChange(fcountdisplay);
                      }
                  }else {
                    this.togglePopupLogin();
                  }
                } else {
                  this.togglePopup();
                }
                */
              }
            
           }
            >
              Write a Review
            </button>
          ) : null}

          {this.state.igvalue !== "" ? (
            <Pencil
              className="pencil"
              onClick={() => {
                this.routeChange(fcountdisplay);
              }}
            ></Pencil>
          ) : null}
          <a
            className="searchAnother"
            onClick={() => {
              this.SearchAgainRouteChange();
            }}
          >
            Search Another Influencer
          </a>
        </div>

        <div className="entirelist">
          {this.state.receivedReviews !== "No reviews" ? (
            <List style={{ position: "relative" }}>
              {this.state.receivedReviews.map((review) => {
                return (
                  <List.Item className="reviews-div">
                    <List className="list">
                      <List.Item style={{ padding: "0px 0px 2px 0px" }}>
                        <a className="reviews-name">{review.name}</a>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 5px 0px" }}>
                        <div style={{ display: "flex" }}>
                          <Suitcase className="reviews-suitcase"></Suitcase>
                          <a className="reviews-company">
                            {review.affiliation}
                          </a>
                        </div>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 5px 0px" }}>
                        <div style={{ display: "flex" }}>
                          {review.rating >= 1 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                          {review.rating >= 2 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                          {review.rating >= 3 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                          {review.rating >= 4 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                          {review.rating >= 5 ? (
                            <StarFill className="star-filled"></StarFill>
                          ) : (
                            <Star className="star-empty"></Star>
                          )}
                        </div>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 3px 0px" }}>
                        <a className="reviews-subject">{review.subject}</a>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 5px 0px" }}>
                        <ShowMoreText 
                        lines={3}
                        more='Show more'
                        less='Show less'
                        className='reviews-paragraph'
                        expanded={false}
                        width={280}>
                          {review.body}
                        </ShowMoreText>
                      </List.Item>

                      <List.Item style={{ padding: "0px 0px 5px 0px" }}>
                        <div className="divfortop">
                          {this.makeAllQ(review).map((value) => {
                            return (
                              <div>
                                <button className="quality2">{value}</button>
                              </div>
                            );
                          })}
                        </div>
                      </List.Item>

                      <List.Item>
                        <a className="reviews-date">{review.date}</a>
                      </List.Item>
                    </List>
                  </List.Item>
                );
              })}
            </List>
          ) : (
            <div>
              <a className="noreviews">
                This influencer does not have any reviews...yet.
              </a>
            </div>
          )}
        </div>
        {this.state.showPopup ? (
          <Popup
            text={this.state.CWmessage}
            closePopup={this.togglePopup.bind(this)}
          ></Popup>
        ) : null}
        {this.state.showPopupSignup ? (
          <PopupSignup
            closePopup={this.togglePopupSignup.bind(this)}
            change={this.togglePopupLoginSignup.bind(this)}
          ></PopupSignup>
        ) : null}
        {this.state.showPopupLogin ? (
          <PopupLogin
            closePopup={this.togglePopupLogin.bind(this)}
            change={this.togglePopupLoginSignup.bind(this)}
          ></PopupLogin>
        ) : null}
      </motion.div>
    );
  }
}

export default withRouter(Influencer);
