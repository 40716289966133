import React, { Component } from 'react';
import Sidebar from './Sidebar'
import {ThreeBars} from '@styled-icons/octicons/'
import "./Contactus.css"
class Contactus extends Component {
    constructor(props){
        super(props)
        this.state={
            showSidebar:false,
        }
    }
    toggleSidebar() {
        const prev = this.state.showSidebar;
        this.setState({
          showSidebar: !prev,
        });
      }
    render() {
        return (
            <div>
                <ThreeBars onClick={()=>{this.toggleSidebar()}} className="threebars"></ThreeBars>
                <a className="auHeader">Contact us</a>
                <a className="auParagraph">This is a prototype. We will have contact information ready soon!</a>
                {this.state.showSidebar ? (
          <Sidebar
            closePopup={this.toggleSidebar.bind(this)}
            change={this.toggleSidebar.bind(this)}
            highlighted={"c"}
          ></Sidebar>
        ) : null}
            </div>
        );
    }
}

export default Contactus;