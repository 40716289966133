import React, { Component } from 'react';
import './PopupSignup.css'
import axios from 'axios';
import {CloseOutline} from '@styled-icons/evaicons-outline'
import {GoogleLogin} from 'react-google-login';
import {withRouter} from 'react-router-dom';
import { CheckmarkOutline } from "@styled-icons/evaicons-outline/";
class PopupSignup extends Component {
  constructor(props){
    super(props)
    this.state={
      firstName: '',
      lastName: '',
      password: '',
      email: '',
      checkbox: false,
      submitted: false,
      inputMistakes:"",
      showAnimation: false,
    }
  }
  changeCheckbox(){
    const prev = this.state.checkbox;
    this.setState({
      checkbox: !prev
    })
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  
  submitSignup = async() =>{
    if(!this.state.email.includes("@")){
      this.setState({
        inputMistakes:"The email above is not valid"
      })
    } else if (this.state.password.length < 7 || !(this.state.password.toLowerCase()!==this.state.password)){
      this.setState({
        inputMistakes:"Passwords need at least 6 characters and 1 capital letter"
      })
    } else if(!(this.state.checkbox && this.state.firstName !== '' && this.state.lastName !== '' && this.state.email !== '' && this.state.password !== '')){
      this.setState({
        inputMistakes:"All fields need to completed"
      })
    }else {
    axios.post("https://influencer-review.herokuapp.com/user/signup",{
        email: this.state.email,
        firstname: this.state.firstName,
        password: this.state.password,
        lastname: this.state.lastName,
    }).then(function (res){
        console.log("submitted user")
    })
    await this.setState({
      showAnimation: true,
    })
    //this.props.change();
    setTimeout(()=>{
      this.props.change();
    }, 3000)
    
  }
  }
    responseGoogle = async(response) => {
      await axios.post("https://influencer-review.herokuapp.com/user/signup",{
        email: response.profileObj.email,
        firstname: response.profileObj.givenName,
        password: "GoogleLoginSlick",
        lastname: response.profileObj.familyName,
        }).then(function (res){
            console.log("submitted user")
        });
        await axios.post("https://influencer-review.herokuapp.com/user/login",{
          email: response.profileObj.email,
          password: "GoogleLoginSlick",
          }).then(function (res){
            localStorage.setItem('token', res.data.token)
        localStorage.setItem('user_id',res.data.user.id)
        localStorage.setItem('userjson',JSON.stringify(res.data.user));
          });
          await this.setState({
            showAnimation: true,
          })
          //this.props.change();
          setTimeout(()=>{
            this.props.closePopup();
          }, 3000)
      
    }
    render() {
      if(this.state.showAnimation){
        return(
          <div>
            <div className='popup'></div>
          <div className='popup_innerS'>
            <button className="signing">Signing up...</button>
          </div>
          </div>
        )
      } else {
      return (
        <div>
        <div className='popup'>
          <div className='popup_innerS'>
            <a className='Signup'>Signup</a>
            <a className='haveAnAccount' >Already have an account?</a>
            <a className='haveAnAccount2' onClick = {this.props.change}>Login</a>
            <a className='headersS' style={{top:"151px", left:"45px"}}>First Name</a>
            <a className='headersS' style={{top:"151px", left:"267px"}}>Last Name</a>
            <a className='headersS' style={{top:"227px", left:"45px"}}>Email</a>
            <a className='headersS' style={{top:"298px", left:"45px"}}>Password</a>
            <input onChange={this.handleChange} name="firstName" placeholder="Enter your first name" className='inputs' style={{top:'176px',left:'35px',width:'206px'}}></input>
            <input onChange={this.handleChange} name="lastName" placeholder="Enter your last name" className='inputs' style={{top:'176px',left:'257px',width:'206px'}}></input>
            <input onChange={this.handleChange} name="email" placeholder="Enter your email address" className='inputs' style={{top:'252px',left:'35px',width:'428px'}}></input>
            <input onChange={this.handleChange} type="password" name="password" placeholder="Enter your password (7+ characters, 1 capital letter)" className='inputs' style={{top:'323px',left:'35px',width:'428px'}}></input>
            <button className='check-box' onClick={()=>{
              this.changeCheckbox();
            }} >
              {this.state.checkbox &&
              <CheckmarkOutline style={{color:"#624BE1",position:"absolute",height:"20px",width:"20px",top:"3px",right:"2px"}}>

              </CheckmarkOutline>
              } 
            </button>
            <a className="iagree">I agree to the Terms and Privacy Policy</a>
            <a className="inputerror">{this.state.inputMistakes}</a>
            {/* <GoogleLogin
            className="googleSignup"
            clientId="248753337271-s4pa70pi1h6pt9pvbuv8f3q6pqr8kut0.apps.googleusercontent.com"
            buttonText="Sign up"
            onSuccess={this.responseGoogle}
            onFailure={this.responseGoogle}
            cookiePolicy={'single_host_origin'}>
            </GoogleLogin> */}
            <button className='signupbutton' onClick={()=>{
              
                this.submitSignup()
                
            
            
            }}>Sign up</button>
            <CloseOutline className='close' onClick={this.props.closePopup}></CloseOutline>
          </div>
        </div>
        
        </div>
      );
    }
  }
  }

export default withRouter(PopupSignup);