import { withRouter } from "react-router-dom";
import axios from "axios";
import React, { Component } from "react";
import "./Home.css";
import { SearchAlt2 } from "@styled-icons/boxicons-regular";
import { ExclamationCircleFill } from "@styled-icons/bootstrap/";
import { Cross } from "@styled-icons/entypo";
import { LoaderAlt } from "@styled-icons/boxicons-regular/";
import {motion} from 'framer-motion';
import {Heart} from '@styled-icons/entypo/Heart'
import Sidebar from './Sidebar';
import {ThreeBars} from '@styled-icons/octicons/'

class Home extends Component {
  constructor(props) {
    super(props);
    this.redErrorMessage = React.createRef()
    this.routeChange = this.routeChange.bind(this);
    this.state = {
      apiResponse: "getting",
      ighandle: "",
      igvalue: "",
      searched: false,
      gotdne: false,
      isLoading: false,
      typing: false,
      showSidebar: false,
    };
    
  }
  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }
  routeChange() {
    let path = '/influencer/'+this.state.ighandle;
    this.props.history.push({
      pathname: path,
      state: { igvalue: this.state.igvalue, ighandle: this.state.ighandle },
    });
  }
  toggleSidebar() {
    const prev = this.state.showSidebar;
    this.setState({
      showSidebar: !prev,
    });
  }
  handleSubmit = async (event) => {
    this.setState({
      isLoading: true,
    });
    
    await axios
      .post("https://influencer-review.herokuapp.com/getEngagement", {
        IGHANDLE: this.state.ighandle,
      }, {
        timeout: 60000
      })
      .then((response) => {
        const ighandlefromres = response.data;
        //const ighandletrimmed = ighandlefromres.trim()
        if (ighandlefromres.charAt(0) === "[") {
          this.setState({
            igvalue: ighandlefromres,
          });
          
          
        
          this.routeChange();
          
          
        } else if (ighandlefromres.trim().charAt(0) === "H") {
          this.setState({
            igvalue: "This account does not exist",
            searched: true,
            isLoading: false,
            gotdne:
              ighandlefromres.charAt(0) === "T" ||
              ighandlefromres.charAt(0) === "N" ||
              ighandlefromres.trim().charAt(0) === "H",
          });
        } else {
          this.setState({
            igvalue: ighandlefromres,
            searched: true,
            isLoading: false,
            gotdne:
              ighandlefromres.charAt(0) === "T" ||
              ighandlefromres.charAt(0) === "N" ||
              ighandlefromres.trim().charAt(0) === "H",
          });
        }
      })
      .catch((error) => {
        this.setState({
          igvalue: "Hmmm... We can't find this account",
          searched: true,
          isLoading: false,
          gotdne: true,
        });
        console.log("got errr while posting data", error);
      });
      
    //event.preventDefault();
  };
  handleChange = (e) => {
    if (this.state.ighandle === "") {
      this.setState({
        gotdne: false,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const renderButtonofInput = () => {
      if (this.state.ighandle === "") {
        return (
          <button className="iconinsideInput">
            <SearchAlt2 className="icon"></SearchAlt2>
          </button>
        );
      } else if (this.state.ighandle !== "" && !this.state.gotdne) {
        return (
          <button className="button-istyping" onClick={(event) => {this.handleSubmit(event)}}>
            <SearchAlt2 className="icon"></SearchAlt2>
          </button>
        );
      } else {
        return (
        <button className="iconinsideInput" onClick={() => {
          if (this.state.searched) {
            document.getElementById("myInput").value = "";
            this.setState({
              ighandle: ''
            })
          }}}>
          <Cross className="icon-cross"></Cross>
        </button>
        );
      }
    };
    return (
    
      <motion.div exit={{opacity:0}} animate={{opacity:1}} initial={{opacity:0}}>
        
        <ThreeBars onClick={()=>{this.toggleSidebar()}} className="threebars"></ThreeBars>
      
        <div className="divInput">
          
          <input
            className={this.state.ighandle === "" ? "input" : "input-istyping"}
            autoCapitalize="off"
            autoComplete="off"
            name="ighandle"
            onChange={this.handleChange}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                this.handleSubmit(event);
              }
            }}
            placeholder="Search an Instagram handle..."
            spellCheck="false"
            id="myInput"
          />
          
          {renderButtonofInput()}
          {this.state.isLoading ? <LoaderAlt className="loaderHome" /> : null}
        </div>
        
        {this.state.gotdne && this.state.ighandle !== "" ? (
            <motion.div exit={{opacity:0}} animate={{opacity:1}} initial={{opacity:0}}>
              <button className="accountnoexist">
                {this.state.ighandle === 'vickyyoon'?<Heart className='icon-forred'></Heart>:<ExclamationCircleFill className="icon-forred"></ExclamationCircleFill>}  
                {this.state.igvalue}
              </button>
              </motion.div>
          ) : (
            ""
          )}
        {this.state.showSidebar ? (
          <Sidebar
            closePopup={this.toggleSidebar.bind(this)}
            change={this.toggleSidebar.bind(this)}
          ></Sidebar>
        ) : null}
      </motion.div>
    
    );
  }
}

export default withRouter(Home);
